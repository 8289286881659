import throttle from 'lodash.throttle'

export default class {
  constructor () {
    this.$stickyButton = document.querySelectorAll('.sticky-button')

    if (this.$stickyButton) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$stickyButton.forEach(element => {
      new StickyButton(element)
    })
  }
}

class StickyButton {
  constructor (item) {
    this.$stickyButton = item
    this.scrollHeight = document.documentElement.scrollHeight
    this.dataTopPourcent = 27
    this.dataBottomPourcent = 60

    this.bindEvents()
  }

  bindEvents () {
    let passiveIfSupported = false

    try {
      const options = {
        get passive () {
          passiveIfSupported = { passive: true }
          return false
        }
      }
      window.addEventListener('test', null, options)
      window.removeEventListener('test', null, options)
    } catch (error) {}

    window.addEventListener('scroll', throttle(this.scrollCalculHandler.bind(this), 5), passiveIfSupported)
  }

  scrollCalculHandler () {
    const currentMiddleScroll = document.documentElement.scrollTop + window.innerHeight / 2
    const dataTopPixel = (this.scrollHeight * this.dataTopPourcent) / 100
    const dataBottomPixel = (this.scrollHeight * this.dataBottomPourcent) / 100

    if (currentMiddleScroll >= dataTopPixel && currentMiddleScroll < dataBottomPixel) {
      this.$stickyButton.classList.add("active")
    } else {
      if (this.$stickyButton.classList.contains('active')) {
        this.$stickyButton.classList.remove("active")
      }
    }
  }
}
