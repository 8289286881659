import { gsap, TweenLite, CSSPlugin } from "gsap/all"

gsap.registerPlugin(CSSPlugin)

export default class {
  constructor () {
    this.$searchMobileWrapper = document.querySelector('.search-bar__mobile-wrapper')
    this.$openButton = document.querySelector('.search-bar__open-button')
    this.$searchButton = document.querySelector('.search-bar__search-button')
    this.$closeButton = document.querySelector('.search-bar__close-button')
    this.$body = document.body
    this.$html = document.documentElement

    this.isOpen = true

    if (this.$searchMobileWrapper) {
      this.bindEvents()
    }
  }

  bindEvents () {
    this.$closeButton.addEventListener('click', () => { this.searchMobileOpenAnimation() })
    this.$openButton.addEventListener('click', () => { this.searchMobileOpenAnimation() })
    this.$searchButton.addEventListener('click', () => { this.searchMobileOpenAnimation() })
  }

  searchMobileOpenAnimation () {
    this.slideAnimationUp(this.$searchMobileWrapper)
    this.slideAnimationUp(this.$searchButton)
    this.$body.classList.toggle("overflow-hidden-body", this.isOpen)
    this.$html.classList.toggle("overflow-hidden-html", this.isOpen)
    this.isOpen = !this.isOpen
  }

  slideAnimationUp (element) {
    TweenLite.to(element, 0.5, { y: this.isOpen ? '0%' : '100%', ease: "power4" })
  }

  slideAnimationDown (element) {
    TweenLite.to(element, 0.5, { y: this.isOpen ? '0%' : '-100%', ease: "power4" })
  }
}
