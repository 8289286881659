// MODULES =====================================================================
import Rails from "@rails/ujs"
import FlashMessage from "../scripts/utils/flash-messages"
import ImageObjectFit from "../scripts/utils/image-object-fit"
import AnimationScroll from "../scripts/utils/animation-scroll"
import ButtonApplyOffers from "../scripts/utils/button-apply-offers"
import CustomSelect from "../scripts/utils/custom-select"
import SearchBar from "../scripts/utils/search-bar"

// CONFIGURATION ===============================================================
if (!window._rails_loaded) { Rails.start() }

window.FlashMessage = FlashMessage
window.Rails = Rails

document.addEventListener("DOMContentLoaded", () => {
  ImageObjectFit()
  AnimationScroll()
  new ButtonApplyOffers()
  new CustomSelect()
  new SearchBar()
})
