import customSelect from 'custom-select'

export default class {
  constructor () {
    this.$select = document.querySelectorAll('.custom-select')

    if (this.$select) {
      // TODO reactive customSelect
      customSelect('select')
    }
  }
}
